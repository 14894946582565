import * as React from "react";
import TextField from "@mui/material/TextField";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import { DataGrid } from "@mui/x-data-grid";
import { useGetHWITableQuery } from "../operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";


const columns = [
    { field: "hwi_timestamp", headerName: "Time", width: "350" },

  {
    field: "hot_water_index",
    headerName: "HWI",
    width: "200",

  },
  { field: "heating_gradient", headerName: "HG", width: "200" },
  {
    field: "cooling_gradient",
    headerName: "CG",
    width: "200",

  },
  { field: "power", headerName: "Power", width: "200" },
  { field: "heating_time", headerName: "Heating time", width: "200" },
  { field: "cooling_time", headerName: "Cooling time", width: "200" },

];

export default function TableHWI() {

  let {serial_number, meter_id } = useParams();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    data: tableData,
    isLoading: istableDataLoading,
    isSuccess: istableDataSuccess,
    isError: istableDataError,
  } = useGetHWITableQuery({meter_id, userTimezone});

  if (istableDataLoading) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading Table</p>
      </>
    );
  } else if (istableDataError) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving Historical Data Table
      </Grid>
    );
  } else {
    return (
      <>
        <Grid item sx={{ width: "100%" }}>
          <Paper sx={{ borderRadius: 3 }}>
            <DataGrid
              sx={{ borderRadius: 3, marginTop: 3 }}
              rows={tableData}
              columns={columns}
              autoHeight
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 5,
                  },
                },
              }}
              autoWidth
              pageSizeOptions={[5, 15, 25]}
              disableSelectionOnClick
              disableRowSelectionOnClick={true}
              columnBuffer={3}
            />
          </Paper>
        </Grid>
      </>
    );
  }
}
