import React from "react";
import Chart from "react-apexcharts";
import Grid from "@mui/material/Grid";
import {
  useGetLSPSStateQuery,
  useGetMicroServiceMonitoringGraphDataQuery,
  useGetTargetDataNowQuery,
  useGetMicroServiceMonitoringForcastQuery,
} from "./operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";

export default function OpperatorDashboardGraph() {
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const {
    data: lspsState,
    isSuccess: isLSPSStateSuccess,
    isLoading: isLSPSStateLoading,
    isError: isLSPSStateError,
  } = useGetLSPSStateQuery(null, {
    pollingInterval: 60000,
  });
  const {
    data: microServiceGraphData,
    isSuccess: isMicroServiceGraphDataSuccess,
    isLoading: isMicroServiceGraphDataLoading,
    isError: isMicroServiceGraphDataError,
  } = useGetMicroServiceMonitoringGraphDataQuery(userTimezone, {
    pollingInterval: 60000,
  });
  const {
    data: microserviceForcastState,
    isLoading: isMicroServiceForcastStateLoading,
    isError: isMicroServiceStateForcastError,
    refetch: refetchMicroServiceForcast,
  } = useGetMicroServiceMonitoringForcastQuery(null, {
    pollingInterval: 60000,
  });
  const {
    data: targetDataNow,
    isLoading: isTargetDataNowLoading,
    isError: isTargetDataNowError,
    refetch: refetchTargetDataNow,
  } = useGetTargetDataNowQuery(null, {
    pollingInterval: 60000,
  });
  if (
    isMicroServiceGraphDataLoading ||
    isLSPSStateLoading ||
    isTargetDataNowLoading ||
    isMicroServiceForcastStateLoading
  ) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading Dashboard Graph Data</p>
      </>
    );
  } else if (
    isLSPSStateError ||
    isMicroServiceGraphDataError ||
    isTargetDataNowError ||
    isMicroServiceStateForcastError
  ) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving current Dashboard Graph Data
      </Grid>
    );
  } else {
    const matchingTimestamps = microserviceForcastState.map((forecast) => {
      const forecastTimestamp = new Date(
        forecast.forecast_timestamp
      ).toLocaleString("en-GB", { timeZone: userTimezone });
      return (
        forecastTimestamp.split(":")[0] + ":" + forecastTimestamp.split(":")[1]
      );
    });

    const forecastGraphData = [];
    const forecastPrevGraphData = [];
    // console.log(matchingTimestamps);
    microServiceGraphData.forEach((item) => {
      const itemTimestamp = new Date(item.interval).toLocaleString("en-GB", {
        timeZone: userTimezone,
      });
      const formattedItemTimestamp =
        itemTimestamp.split(":")[0] + ":" + itemTimestamp.split(":")[1];
      const isMatchingForecast = matchingTimestamps[0].includes(
        formattedItemTimestamp
      );
      const isMatchingForecastPrev = matchingTimestamps[1].includes(
        formattedItemTimestamp
      );
      forecastGraphData.push(
        isMatchingForecast ? microserviceForcastState[0].forecast / 1000000 : null
      );
      forecastPrevGraphData.push(
        isMatchingForecastPrev
          ? microserviceForcastState[1].forecast / 1000000
          : null
      );
    });
    const data = {
      series: [
        {
          name: "Max Return",
          type: "line",
          color: "#FF5C00",
          data: microServiceGraphData?.map((item) => {
            return lspsState?.max_return;
          }),
        },
        {
          name: "Target Demand",
          type: "line",
          color: "#26BF00",
          data: microServiceGraphData?.map((item) => {
            return targetDataNow?.target ? targetDataNow?.target / 1000000 : null;
          }),
        },
        {
          name: "Current Demand",
          type: "line",
          color: "#2E93fA",
          data: microServiceGraphData?.map((item) =>
            item.current_demand ? item.current_demand / 1000000 : null
          ),
        },
        {
          name: "Integrated Demand",
          type: "line",
          color: "#A53838",
          data: microServiceGraphData?.map((item) =>
            item.latest_integrated_demand
              ? item.latest_integrated_demand / 1000000
              : null
          ),
        },
        {
          name: "Tjommie Demand",
          type: "area",
          color: "#CACACA",
          data: microServiceGraphData?.map((item) =>
            item.geyser_demand ? item.geyser_demand / 1000000 : null
          ),
        },
        {
          name: "Forecast",
          type: "scatter",
          color: "#DC6CFE",
          data: forecastGraphData,
        },
        {
          name: "ForecastPrev",
          type: "scatter",
          color: "#DC6CFE",
          data: forecastPrevGraphData,
        },
      ],
      options: {
        chart: {
          stacked: false,
          animations: {
            enabled: false,
            dynamicAnimation: {
              enabled: false,
            },
          },
          toolbar: {
            export: {
              csv: {
                filename: `Operator_Dashboard_Report`,
                dateFormatter(datetime) {
                  const dateObj = new Date(datetime);
                  const hours = dateObj.getHours().toString().padStart(2, "0");
                  const minutes = dateObj
                    .getMinutes()
                    .toString()
                    .padStart(2, "0");
                  const day = dateObj.getDate().toString().padStart(2, "0");
                  const month = (dateObj.getMonth() + 1)
                    .toString()
                    .padStart(2, "0");
                  const year = dateObj.getFullYear().toString();
                  return `${year}-${month}-${day} ${hours}:${minutes}`;
                },
              },
            },
          },
        },
        markers: {
          size: [0, 0, 0, 0, 0, 10, 10],
          fillOpacity: [0, 0, 0, 0, 0, 1, 0.4],
        },
        fill: {
          type: "solid",
        },
        legend: {
          show: false,
        },

        dataLabels: {
          enabled: false,
        },
        title: {
          text: "MVA",
          align: "left",
          style: {
            fontSize: "20px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        stroke: {
          dashArray: [5, 5, 0, 0, 0, 0, 0],
        },
        xaxis: {
          categories: microServiceGraphData?.map((item) => item.interval),
          type: "datetime",
          labels: {
            datetimeUTC: false,
          },
        },
        yaxis: {
          title: {
            text: "MVA",
          },
          decimalsInFloat: 2,
        },
        tooltip: {
          shared: true,
          x: {
            format: "yyyy MMM dd HH:mm",
          },
        },
      },
    };
    return (
      <Grid item sx={{ width: "100%" }}>
        <Chart options={data.options} series={data.series} height={400} />
      </Grid>
    );
  }
}
