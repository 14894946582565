import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon, Refresh as RefreshIcon } from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import HomeIcon from "@mui/icons-material/Home";
import tjommiePic from "../images/tjommie.jpg";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import { useNavigate } from "react-router-dom";
import SettingsButton from "../../Features/UserSettings/SettingsButton";
import ScheduleLS from "../../Features/LoadShiftSchedule/ScheduleLoadShift";
import SchedulePS from "../../Features/SchedulePeakShaving/SchedulePeakShaving";
import PublicHoliday from "../../Features/Financial/PublicHoliday";
import TOU from "../../Features/Financial/TOU";
import Tariff from "../../Features/Financial/Tariff";
import SsidChartIcon from '@mui/icons-material/SsidChart';
import TuneIcon from '@mui/icons-material/Tune';
import BoltIcon from '@mui/icons-material/Bolt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';

import StarIcon from "@mui/icons-material/Star";
import ResetGeysers from "../../Features/OperatorDashboard/ResetGeysers";

const styles = {
  appBar: {
    backgroundColor: "#333333",
    width: "100%",
    zIndex: 1400,
  },
  menuButton: {
    marginRight: 25,
  },
  refreshButton: {
    marginLeft: "auto",
  },
  tjommiePic: {
    width: 85,
    height: 32,
    marginRight: 25,
  },
  operatorText: {
    marginRight: "auto",
    marginLeft: 4,
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  topList: {
    flexGrow: 1,
  },
};

export default function OperatorNavbar() {
  const navigate = useNavigate();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };

  const handleHome = () => {
    navigate("/");
    setIsDrawerOpen(false);
  };

  const handleRawData = () => {
    navigate("/rawdata/");
    setIsDrawerOpen(false);
  };

  const handleMunicipality = () => {
    navigate("/municipality/");
    setIsDrawerOpen(false);
  }; 

  const handleInstaller = () => {
    navigate("/installer/");
    setIsDrawerOpen(false);
  }; 

  const handleADMD = () => {
    navigate("/operator/admd/");
    setIsDrawerOpen(false);
  };

  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };

  const handleRefresh = () => {
    console.log("Refreshing...");
  };

  return (
    <>
      <AppBar position="absolute" style={styles.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            style={styles.menuButton}
            onClick={isDrawerOpen ? handleDrawerClose : handleDrawerOpen}
          >
            {isDrawerOpen ? <MenuOpenIcon /> : <MenuIcon />}
          </IconButton>
          <img src={tjommiePic} alt="Tjommie" style={styles.tjommiePic} />
          <Typography variant="h6" noWrap style={styles.operatorText}>
            DSM Operator
          </Typography>
          <IconButton
            color="inherit"
            aria-label="refresh"
            style={styles.refreshButton}
            onClick={handleRefresh}
          >
            <RefreshIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        anchor="left"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
      >
        <div style={styles.drawerContainer}>
          <List sx={{ width: 260, marginTop: 10 }} style={styles.topList}>
            <ListItem sx={{ marginTop: 2 }} button onClick={handleHome}>
              <ListItemIcon>
                <TuneIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>

            <SettingsButton />
            <ScheduleLS />
            <SchedulePS />
            <PublicHoliday/>
            <TOU/>
            <Tariff/>
            <ResetGeysers />
            <ListItem
              sx={{ marginTop: 2 }}
              button
              onClick={handleADMD}
            >
              <ListItemIcon>
                <StarIcon />
              </ListItemIcon>
              <ListItemText primary="ADMD" />
            </ListItem>
          </List>

          <List sx={{ width: 260 }}>
  
            <ListItem sx={{ marginTop: 2 }} button onClick={handleRawData}>
              <ListItemIcon>
                <SsidChartIcon />
              </ListItemIcon>
              <ListItemText primary="Raw Data" />
            </ListItem>

            <ListItem sx={{ marginTop: 2 }} button onClick={handleMunicipality}>
              <ListItemIcon>
                <BoltIcon />
              </ListItemIcon>
              <ListItemText primary="Municipality" />
            </ListItem>

            <ListItem sx={{ marginTop: 2 }} button onClick={handleInstaller}>
              <ListItemIcon>
                <LocalShippingIcon />
              </ListItemIcon>
              <ListItemText primary="Installer" />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
}
