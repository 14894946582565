import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import {
  useGetLSPSStateQuery,
  useUpdateLSPSStateMutation,
  useGetDashboardStatusQuery,
} from "./operatorDashboardAPISlice";
import CircularProgress from "@mui/material/CircularProgress";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

export default function OperatorDashboardToggle() {
  const [selectedPeakShaving, setSelectedPeakShaving] = useState(false);
  const [selectedLoadShifting, setSelectedLoadShifting] = useState(false);
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    data: lspsState,
    isLoading: isLSPSStateLoading,
    isError: isLSPSStateError,
  } = useGetLSPSStateQuery();

  const {
    data: dashboardStatus,
    isLoading: isDashboardStatusLoading,
    isError: isDashboardStatusError,
  } = useGetDashboardStatusQuery(userTimezone, {
    pollingInterval: 60000,
  });

  const [
    updateLSPSState,
    {
      data: updatedLSPSState,
      isLoading: updateLSPSStateLoading,
      isError: updateLSPSStateError,
      error: LSPSStateUpdateError,
    },
  ] = useUpdateLSPSStateMutation();

  const handleChangePeakShaving = (event) => {
    const { checked } = event.target;
    setSelectedPeakShaving(checked);
    const request = {
      body: {
        switch_peak_shaving: checked,
        switch_load_shifting: selectedLoadShifting,
      },
    };
    updateLSPSState(request);
  };

  const handleChangeLoadShifting = (event) => {
    const { checked } = event.target;
    setSelectedLoadShifting(checked);
    const request = {
      body: {
        switch_peak_shaving: selectedPeakShaving,
        switch_load_shifting: checked,
      },
    };
    updateLSPSState(request);
  };

  React.useEffect(() => {
    if (lspsState) {
      setSelectedPeakShaving(lspsState.switch_peak_shaving);
      setSelectedLoadShifting(lspsState.switch_load_shifting);
    }
  }, [lspsState]);

  if (
    isLSPSStateLoading ||
    updateLSPSStateLoading ||
    isDashboardStatusLoading
  ) {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Grid>
        <Grid item alignItems="center" justifyContent="center"></Grid>
        <p>Loading current PS and LS state</p>
      </>
    );
  } else if (isLSPSStateError) {
    return (
      <Grid
        style={{ marginLeft: "50px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        Error retrieving current Load shifting / Peak shaving state
      </Grid>
    );
  } else {
    return (
      <>
        <Grid
          style={{ marginLeft: "50px" }}
          item
          alignItems="center"
          justifyContent="center"
        >
          <FormGroup row>
            <FormControlLabel
              control={
                <Switch
                  checked={selectedPeakShaving}
                  onChange={handleChangePeakShaving}
                  value="switch_peak_shaving"
                />
              }
              label="Peak-shaving"
            />
            <Box
              component="span"
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                display: "inline-block",
                // backgroundColor: 'black',
                backgroundColor: lspsState.peak_shaving ? "#03A60A" : "#FF0000",
                marginLeft: "-15px",
                marginRight: "15px",
              }}
            />
            <FormControlLabel
              control={
                <Switch
                  checked={selectedLoadShifting}
                  onChange={handleChangeLoadShifting}
                  value="switch_load_shifting"
                />
              }
              label="Load shifting"
            />
            <Box
              component="span"
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50%",
                display: "inline-block",
                // backgroundColor: 'black',
                backgroundColor: lspsState.load_shifting ? "#03A60A" : "#FF0000",
                marginLeft: "-15px",
                marginRight: "15px",
              }}
            />
          </FormGroup>
        </Grid>
        <DashboardFractionValues dashboardStatus={dashboardStatus} />
      </>
    );
  }
}

function DashboardFractionValues({ dashboardStatus }) {
  return (
    <>
      <Grid
        style={{ marginLeft: "auto" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          Connected - {dashboardStatus.devices_connected} /{" "}
          {dashboardStatus.total_geysers}
        </Typography>
      </Grid>
      <Grid
        style={{ marginLeft: "20px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          Under Control - {dashboardStatus.total_meter_control} /{" "}
          {dashboardStatus.devices_connected}
        </Typography>
      </Grid>
      <Grid
        style={{ marginLeft: "20px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        <Typography>
          Thermo On - {dashboardStatus.total_geysers_on} /{" "}
          {dashboardStatus.devices_connected}
        </Typography>
      </Grid>
      <Grid
        style={{ marginLeft: "20px" }}
        item
        alignItems="center"
        justifyContent="center"
      >
        <Typography>{dashboardStatus.time_of_query}</Typography>
      </Grid>
    </>
  );
}
